import { initReactQueryAuth } from "react-query-auth";

import { Spinner } from "@/components/Elements";
import {
  AuthUser,
  LoginCredentialsDTO,
  RegisterCredentialsDTO,
  UserResponse,
  getUser,
  loginWithEmailAndPassword,
  registerWithEmailAndPassword,
} from "@/features/auth";
import { useDialogStore } from "@/stores/upgrade";
import storage from "@/utils/storage";
import { identifyUser } from "../features/analytics/api/identifyUser";
import { getIdentifyTraits } from "../features/analytics/utils/traits";
import { getSubscription } from "../features/auth/api/getSubscription";
import { handleSendVerificationEmail } from "./verification";

type RegistrationResponse = {
  accessToken: string;
  refreshToken: string;
};
interface BeamerConfig {}

declare var beamer_config: BeamerConfig;
declare var Beamer: any;

declare global {
  interface Window {
    fpr?: (event: string, data: object) => void;
    FPROM?: { data?: { cookie_ref_id?: string } };
  }
}

async function handleUserResponse(data: UserResponse) {
  const { jwt, _ } = data;

  if (typeof jwt !== "string") {
    logoutFn();
    return null;
  }

  storage.setToken(jwt);
  const user = await getUser();

  return user;
}

async function handleRegistrationResponse(data: RegistrationResponse) {
  const { accessToken, refreshToken } = data;

  if (typeof accessToken !== "string") {
    logoutFn();
    return null;
  }

  storage.setToken(accessToken);
  storage.setRefreshToken(refreshToken);
  const user = await getUser();
  return user;
}

async function checkIfUserEmailVerified(user: AuthUser) {
  if (
    user &&
    !user.verified &&
    window.location.pathname !== "/app/verify-email" &&
    !window.location.pathname.startsWith("/app/verification/")
  ) {
    window.location.assign(window.location.origin + "/app/verify-email");
  }
}

async function checkIfUserOnboarded(user: AuthUser) {
  if (
    user &&
    !user.onBoarded &&
    !window.location.pathname.startsWith("/app/onboarding")
  ) {
    window.location.assign(window.location.origin + "/app/onboarding");
  }
}

async function identifyUserFn(user: AuthUser) {
  if (typeof window.fpr !== "undefined") {
    window.fpr("referral", { email: user.username });
    if (
      typeof window.FPROM !== "undefined" &&
      window.FPROM.data &&
      window.FPROM.data.cookie_ref_id
    ) {
      user.affiliate_link = window.FPROM.data.cookie_ref_id;
    }
  }

  const traits = getIdentifyTraits(user);

  identifyUser({
    userId: user.id.toString(),
    traits: JSON.stringify(traits),
    user,
  });
}

async function validateSubscription() {
  await getSubscription()
    .then((res) => {
      const status = res.status;
      const appsumo = res.appsumo;

      if (status !== "active" && appsumo !== true) {
        logoutFn();
      }
    })
    .catch(() => {
      logoutFn();
    });
}

async function loadUser() {
  const ignoreAuthPath = "/ignore-auth";

  if (window.location.pathname.includes(ignoreAuthPath)) {
    return null;
  }

  if (storage.getToken()) {
    // Validate JWT token
    const data = await getUser().catch(() => {
      logoutFn();
    });

    if (!data) {
      return null;
    }

    // Validate subscription
    // await validateSubscription();

    // Check if the user's email is verified
    if (!data.verified) {
      await checkIfUserEmailVerified(data);
      return data;
    }

    // Check if onboarding should be shown in the next session
    if (storage.shouldShowOnboardingNextSession()) {
      storage.clearShowOnboardingNextSession();
      window.location.assign(window.location.origin + "/app/onboarding");
      return data;
    }

    // Check if Beamer is enabled
    if (typeof beamer_config != "undefined" && typeof Beamer != "undefined") {
      Beamer.init();
    }

    // Identify user
    identifyUserFn(data);

    return data;
  }
  return null;
}

async function loginFn(data: LoginCredentialsDTO) {
  const response = await loginWithEmailAndPassword(data);

  if (!response) {
    return null;
  }

  const { accessToken, refreshToken } = response;
  storage.setToken(accessToken);
  storage.setRefreshToken(refreshToken);

  let user = await handleUserResponse({
    jwt: accessToken,
    user: {} as AuthUser,
  });

  // Check if there's a redirect path stored and navigate to it
  const redirectPath = storage.getLastVisitedPath();
  const shouldRedirect =
    (redirectPath?.startsWith("/app/documents/") &&
      !redirectPath?.startsWith("/app/documents/preview/")) ||
    redirectPath?.startsWith("/app/extension/");
  if (redirectPath && shouldRedirect) {
    window.location.assign(window.location.origin + redirectPath);
    storage.clearLastVisitedPath();
  }

  if (!useDialogStore.getState().legacyUserDialogDisplayed) {
    if (user?.legacy) {
      useDialogStore.getState().closeDialog();
      useDialogStore.getState().openDialog("legacyUser", "test");
    }
  }

  // Check if the user's email is verified
  if (!user?.verified) {
    await checkIfUserEmailVerified(user as AuthUser);
  }

  // Check if user has completed onboarding
  if (!user?.onBoarded) {
    await checkIfUserOnboarded(user as AuthUser);
  }

  // Check if Beamer is enabled
  if (typeof beamer_config != "undefined" && typeof Beamer != "undefined") {
    Beamer.init();
  }

  identifyUserFn(user as AuthUser);

  return user;
}

async function registerFn(data: RegisterCredentialsDTO) {
  const response = await registerWithEmailAndPassword(data);

  if (!response) {
    return null;
  }

  const { accessToken, refreshToken } = response;
  storage.setToken(accessToken);
  storage.setRefreshToken(refreshToken);

  let user = await handleRegistrationResponse({
    accessToken,
    refreshToken,
  });

  // redirect user to the app
  if (user) {
    if (data.googleRegistration === "false") {
      await handleSendVerificationEmail({
        email: data.email,
        id: user?.id,
      });
    }
    window.location.assign(window.location.origin + "/app/verify-email");
  }

  return user;
}

async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin as unknown as string);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | null,
  unknown,
  LoginCredentialsDTO,
  RegisterCredentialsDTO
>(authConfig);
