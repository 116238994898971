import {
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Tag,
} from "@/components/Elements";
import { useUpdateDocument } from "@/features/documents/api/updateDocument";
import { useDocumentStore } from "@/stores/document";
import { useNotificationStore } from "@/stores/notifications";
import { cn } from "@/utils/style";
import clsx from "clsx";
import {
  CheckSquare,
  ChevronDown,
  ChevronRight,
  Copy,
  Square,
} from "lucide-react";
import { useEffect, useState } from "react";
import { TbDots } from "react-icons/tb";

const valueColorMap = {
  h1: "emerald",
  h2: "sky",
  h3: "amber",
  h4: "rose",
  h5: "zinc",
};

const headerMarginMap = {
  h1: "ml-0",
  h2: "ml-0",
  h3: "ml-4",
  h4: "ml-6",
  h5: "ml-8",
};

function HeaderTag({ headerType, color }) {
  return (
    <Tag variant="small" color={color}>
      {headerType.toUpperCase()}
    </Tag>
  );
}

function AssetButton({ assetSelected, onClick }) {
  return (
    <Button
      variant="buttonIcon"
      className={cn(
        "mr-1 align-top self-start hover:bg-zinc-200",
        assetSelected && "hover:bg-emerald-600/15"
      )}
      buttonIcon={assetSelected ? <CheckSquare /> : <Square />}
      tooltipContent={assetSelected ? "Remove header" : "Add header"}
      onClick={onClick}
    />
  );
}

export function AssetMenu({
  handlePasteHeadings,
  headerText,
  headerType,
  assetText,
  assetsVisible,
  setAssetsVisible,
  handleSaveToOutline,
  url,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { addNotification } = useNotificationStore();

  return (
    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenuTrigger>
        <Button
          variant="buttonIcon"
          size="sm"
          className={cn(
            "invisible group-hover/asset-header:visible",
            dropdownOpen && "visible"
          )}
          buttonIcon={<TbDots />}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        ></Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuGroup
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DropdownMenuItem
            onClick={(event) => {
              handlePasteHeadings([
                {
                  header: headerText,
                  user_header_tag: headerType,
                  url: url,
                },
              ]);
              event.stopPropagation();
            }}
          >
            Paste to editor
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={(event) => {
              handleSaveToOutline();
              event.stopPropagation();
            }}
          >
            Save to outline
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function PasteHeaderButton({
  handlePasteHeadings,
  headerText,
  headerType,
  url,
  className,
}) {
  return (
    <Button
      variant="buttonIcon"
      className={cn("invisible group-hover/asset-header:visible", className)}
      buttonIcon={<Copy />}
      tooltipContent={`Paste to editor`}
      onClick={(event) => {
        handlePasteHeadings([
          {
            header: headerText,
            user_header_tag: headerType,
            url: url,
          },
        ]);
        event.stopPropagation();
      }}
    />
  );
}

export function PasteAssetButton({
  handlePasteContent,
  handlePasteCitation,
  headerText,
  url,
  className,
}) {
  // Ensure that both handlePasteContent and handlePasteCitation are functions before calling them
  const onClickHandler = (event) => {
    if (typeof handlePasteContent === "function") {
      handlePasteContent([headerText]);
    }
    if (typeof handlePasteCitation === "function") {
      handlePasteCitation(url);
    }
    event.stopPropagation();
  };

  return (
    <Button
      variant="buttonIcon"
      className={cn("invisible group-hover/asset:visible", className)}
      buttonIcon={<Copy />}
      onClick={onClickHandler}
    />
  );
}

export function ViewAssetButton({ assetsVisible }) {
  return (
    <Button
      variant="buttonIcon"
      className="mr-1 invisible group-hover:visible align-top self-start"
      buttonIcon={assetsVisible ? <ChevronDown /> : <ChevronRight />}
    />
  );
}

export function SerpAsset({
  headerText,
  headerType = "h1",
  assetText,
  url,
  selectionEnabled,
  onClick,
  selected: initialAssetSelected = false,
  color = valueColorMap[headerType.toLowerCase()] ?? "emerald",
  handlePasteHeadings,
  handlePasteCitation,
  handlePasteContent,
}) {
  const [assetsVisible, setAssetsVisible] = useState(false);
  const [assetSelected, setAssetSelected] = useState(false);
  const { document, setDocument } = useDocumentStore();
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
  });
  const { addNotification } = useNotificationStore();

  const handleAssetClick = (actionType, index) => {
    if (onClick) {
      onClick({
        header: headerText,
        user_header_tag: headerType,
        url: url,
        actionType,
        index,
      });
      setAssetSelected(!assetSelected);
    } else {
      setAssetsVisible(!assetsVisible);
    }
  };

  const handleUpdateDocument = (outline) => {
    // Assign an ID to each asset based on its index
    const assetsWithId = outline.map((asset, index) => ({
      ...asset,
      id: index + 1, // ID is index + 1
    }));

    const outline_map = assetsWithId.reduce((map, asset, index) => {
      map[index] = {
        id: asset.id, // Include the ID in the outline map
        header: asset.header,
        url: asset.url,
        user_header_tag: asset.user_header_tag,
        instruction: asset.instruction,
      };
      return map;
    }, {});

    updateDocumentMutation
      .mutateAsync({
        ...document,
        metadata: {
          ...document.metadata,
          outline: JSON.stringify(outline_map),
        },
      })
      .then(() => {
        addNotification({
          title: "Saved to outline",
          message: "Your content has been saved to your outline.",
          type: "success",
        });
      });
  };

  const handleSaveToOutline = async () => {
    const documentOutline = document.metadata.outline
      ? JSON.parse(document.metadata.outline)
      : {};

    if (documentOutline.length === 30) {
      addNotification({
        title: "Heading limit reached",
        message:
          "You've hit the cap of 30 headings. Please refine your existing headings before adding more.",
        type: "error",
      });
      return;
    }

    const newHeader = {
      header: headerText,
      user_header_tag: headerType,
      url: url,
      instruction: "",
      id: Object.keys(documentOutline).length + 1,
    };

    const headerExists = Object.values(documentOutline).some(
      (asset) => asset.header === newHeader.header
    );

    if (headerExists) {
      addNotification({
        title: "Heading already exists",
        message: "This heading already exists in your outline.",
        type: "error",
      });
      return;
    }

    const updatedOutline = {
      ...documentOutline,
      [Object.keys(documentOutline).length]: newHeader,
    };

    handleUpdateDocument(Object.values(updatedOutline));
  };

  useEffect(() => {
    setAssetSelected(initialAssetSelected);
  }, [initialAssetSelected]);

  return (
    <Collapsible
      className={cn(
        `transition-colors duration-200 relative px-2 py-2 text-2xs bg-white rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20 hover:shadow-md hover:shadow-zinc-900/5 dark:bg-zinc-900 dark:hover:shadow-zinc-50/5`,
        assetSelected && "bg-emerald-600/15",
        headerMarginMap[headerType]
      )}
      onOpenChange={() =>
        assetSelected ? handleAssetClick("remove") : handleAssetClick("add")
      }
      open={assetsVisible}
    >
      <div className="flex group/asset-header">
        <CollapsibleTrigger
          className={clsx(
            "w-full text-start flex items-center text-zinc-900 justify-between",
            assetSelected && "text-white"
          )}
          onClick={(event) => event.stopPropagation()}
        >
          <div className="flex items-center justify-start w-full text-start text-zinc-900">
            <HeaderTag headerType={headerType} color={color} />
            <span
              className={cn(
                "transition-all dark:text-zinc-50 pl-2 self-center"
              )}
            >
              {headerText}
            </span>
          </div>
        </CollapsibleTrigger>
        {!selectionEnabled && !assetSelected ? (
          <div className="flex items-center absolute right-0.5 group-hover/asset-header:bg-white dark:group-hover/asset-header:bg-zinc-900 px-2 top-2">
            <PasteHeaderButton
              handlePasteHeadings={handlePasteHeadings}
              headerText={headerText}
              url={url}
              className="invisible group-hover/asset-header:visible"
              headerType={headerType}
            />
            <AssetMenu
              assetText={assetText}
              headerType={headerType}
              handlePasteHeadings={handlePasteHeadings}
              assetsVisible={assetsVisible}
              setAssetsVisible={setAssetsVisible}
              headerText={headerText}
              handleSaveToOutline={handleSaveToOutline}
              url={url}
            />
          </div>
        ) : (
          <AssetButton
            assetSelected={assetSelected}
            onClick={() => {
              assetSelected
                ? handleAssetClick("remove")
                : handleAssetClick("add");
            }}
          />
        )}
      </div>
      <CollapsibleContent>
        {assetText && assetText.length > 0 ? (
          assetText.map((text, index) => (
            <div
              key={index}
              className="flex justify-between ml-1 mt-2 group/asset-text"
            >
              <p
                key={index}
                className="transition-all text-2xs text-zinc-900 dark:text-white"
              >
                {text}
              </p>
              <div className="flex items-center ml-2">
                <PasteAssetButton
                  handlePasteCitation={handlePasteCitation}
                  handlePasteContent={handlePasteContent}
                  headerText={text}
                  url={url}
                  className="invisible group-hover/asset-text:visible"
                />
              </div>
            </div>
          ))
        ) : (
          <p className="mt-2 text-zinc-400 flex items-center text-center dark:text-zinc-600">
            No content available for this heading
          </p>
        )}
      </CollapsibleContent>
    </Collapsible>
  );
}
