import {
  Button,
  DropdownDialogItem,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input,
  Logo,
} from "@/components/Elements";
import { useEditorStore } from "@/stores/editor";
import { cn } from "@/utils/style";
import {
  DocumentTextIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TbAlertCircleFilled, TbBolt, TbDots, TbPlus } from "react-icons/tb";
import { Link } from "react-router-dom";

import { useIsDocumentRoute } from "@/hooks/useIsDocumentRoute";
import { useAuth } from "@/lib/auth";
import { useUpdateDocument } from "../../features/documents/api/updateDocument";
import { EditorOptionsMenu } from "../../features/editor/components/EditorOptionsMenu";
import { ShareMenu } from "../../features/editor/components/ShareMenu";
import { useDocumentStore } from "../../stores/document";
import ToolbarPlugin from "../AdvancedEditor/plugins/ToolbarPlugin";
import { Spinner } from "../Elements";
import { Navigation, useNavigationStore } from "./MobileNavigation";
import { SidebarToggle } from "./SidebarToggle";

const AiDraftSpinner = () => {
  return (
    <div className="ml-4 bg-emerald-600/10 rounded-full flex space-x-2 px-3 items-center py-1">
      <Spinner size="sm" />
      <p className="text-2xs text-emerald-700 dark:text-emerald-200 flex-shrink truncate font-medium">
        Generating AI article...
      </p>
    </div>
  );
};

const DateFormat = (date) => {
  const lastEdited = dayjs(date).locale("en");
  if (dayjs().diff(lastEdited, "year") >= 1) {
    return `Edited ${lastEdited.fromNow()}`;
  } else if (dayjs().diff(lastEdited, "day") >= 1) {
    return `Edited ${lastEdited.format("MMM D")}`;
  } else {
    return `Edited ${lastEdited.fromNow()}`;
  }
};

const DOCUMENT_NAMES = {
  FIRST_TAB: "Tab 1",
};

const fraseDocumentNameToIcon = {
  [DOCUMENT_NAMES.FIRST_TAB]: (
    <DocumentTextIcon className="w-3 h-3 mr-2 text-zinc-600 dark:text-zinc-400" />
  ),
};

const DeleteTabDialog = ({
  name,
  handleDeleteTab,
  onClose,
  onSelect,
  onOpenChange,
}) => {
  const body = (
    <>
      Are you sure you want to delete the following tab?
      <ul className="list-disc list-inside space-y-2 my-2">
        <li
          key={name}
          className="list-none font-semibold text-zinc-900 dark:text-white dark:bg-zinc-700 border rounded-md p-2 pl-4"
        >
          {name}
        </li>
      </ul>
    </>
  );

  const confirmButton = (
    <Button
      variant="destructiveBlur"
      className="bg-red-600"
      onClick={() => {
        onOpenChange(false);
        handleDeleteTab();
      }}
    >
      Delete
    </Button>
  );

  return (
    <DropdownDialogItem
      icon={<TrashIcon />}
      label="Delete Tab"
      title="Delete Tab"
      body={body}
      close={onOpenChange}
      confirmButton={confirmButton}
      onSelect={onSelect}
      onOpenChange={onOpenChange}
    />
  );
};

const RenameTab = ({ initialName, onRename, onCancel }) => {
  const [nameInput, setNameInput] = useState(initialName);

  return (
    <Input
      value={nameInput}
      onChange={(val) => setNameInput(val)}
      onBlur={onCancel}
      className={
        "h-7 px-2 text-xs font-medium truncate flex items-center whitespace-nowrap rounded-md bg-zinc-100 dark:bg-zinc-700  dark:hover:bg-white/5 dark:hover:text-white dark:text-white"
      }
      containerClassName={"w-32 [&:not(:first-child)]:ml-2"}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onRename(nameInput);
        } else if (e.key === "Escape") {
          onCancel();
        }
      }}
      autoFocus
    />
  );
};

const TabButton = React.forwardRef((props, forwardedRef) => {
  const {
    label,
    icon,
    index,
    isActive,
    onClick,
    isRenaming,
    setIsRenaming,
    handleDeleteTab,
    isLast,
    readOnly,
  } = props;

  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [hasOpenDialog, setHasOpenDialog] = React.useState(false);
  const contextMenuTriggerRef = React.useRef(null);

  const handleDialogItemSelect = () => {
    contextMenuTriggerRef.current?.focus();
  };

  const handleDialogItemOpenChange = (open) => {
    if (!open) {
      setContextMenuOpen(false);
    }
    setHasOpenDialog(open);

    // Delay restoring pointer events
    setTimeout(() => {
      document.body.style.pointerEvents = "auto";
    }, 2000);
  };

  return (
    <div className="transition-all duration-1000 flex flex-1 truncate max-w-fit group">
      <div className="flex flex-col flex-1 truncate max-w-fit">
        <div
          {...props}
          ref={forwardedRef}
          onContextMenu={(event) => {
            event.preventDefault();
            setContextMenuOpen(true);
          }}
          className={cn(
            "flex-1 flex flex-row truncate max-w-fit transition-transform duration-1000 py-0.5 px-2 min-w-2 text-xs font-medium items-center hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-md cursor-pointer",
            isActive
              ? "text-zinc-900 hover:text-zinc-900 dark:text-white dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none bg-zinc-100 ring-white/10 dark:bg-white/5"
              : "text-zinc-600 hover:text-zinc-900 dark:text-zinc-400 dark:ring-white/10 dark:hover:bg-white/5 dark:hover:text-white focus:outline-none focus:ring-transparent opacity-70"
          )}
          onClick={(event) => {
            event.preventDefault();
            onClick();
          }}
        >
          <span className="flex-grow truncate">{label}</span>
          {isActive && !readOnly && (
            <DropdownMenu
              onOpenChange={setContextMenuOpen}
              modal
              open={contextMenuOpen}
            >
              <DropdownMenuTrigger ref={contextMenuTriggerRef} asChild>
                <Button
                  buttonIcon={
                    <TbDots
                      className="w-3 h-3 text-zinc-600 dark:text-zinc-400 flex-shrink-0"
                      style={{
                        strokeWidth: 2.5,
                      }}
                    />
                  }
                  variant="floatingIcon"
                  className="ml-4 flex-shrink-0"
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent hidden={hasOpenDialog}>
                <DropdownMenuItem onSelect={() => setIsRenaming(true)}>
                  <PencilIcon className="w-3 h-3 mr-2" />
                  Rename Tab
                </DropdownMenuItem>
                {!isLast && (
                  <DeleteTabDialog
                    name={label}
                    handleDeleteTab={handleDeleteTab}
                    onClose={handleDialogItemOpenChange}
                    onSelect={handleDialogItemSelect}
                    onOpenChange={handleDialogItemOpenChange}
                  />
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
    </div>
  );
});

export const DocumentTab = ({
  name,
  isActive,
  onClick,
  handleRenameTab,
  handleDeleteTab,
  index,
  isLast,
  readOnly,
}: {
  name: string;
  isActive: boolean;
  isLast: boolean;
  onClick: () => void;
  handleRenameTab: (index: number, newName: string) => void;
  handleDeleteTab: (index: number) => void;
  index: number;
  readOnly: boolean;
}) => {
  const [isRenaming, setIsRenaming] = useState(false);

  const Icon = fraseDocumentNameToIcon[name] || (
    <DocumentTextIcon className="w-3 h-3 mr-2 text-zinc-600 dark:text-zinc-400" />
  );

  if (isRenaming) {
    return (
      <RenameTab
        initialName={name}
        onRename={(newName: string) => {
          handleRenameTab(index, newName);
          setIsRenaming(false);
        }}
        onCancel={() => setIsRenaming(false)}
      />
    );
  }

  return (
    <TabButton
      label={name}
      isLast={isLast}
      icon={Icon}
      isActive={isActive}
      onClick={onClick}
      isRenaming={isRenaming}
      setIsRenaming={setIsRenaming}
      handleDeleteTab={handleDeleteTab}
      readOnly={readOnly}
    />
  );
};

const TabInput = ({
  showInput,
  setNewTabName,
  newTabName,
  handleTabUpdate,
}) => {
  return (
    <Input
      onChange={(value) => setNewTabName(value)}
      value={newTabName}
      onBlur={() => showInput(false)}
      className={cn(
        "h-7 px-2 text-xs font-medium truncate flex items-center whitespace-nowrap rounded-md bg-zinc-50 dark:bg-zinc-800 dark:hover:bg-zinc/5 dark:hover:text-white dark:text-white"
      )}
      placeholder="Tab name"
      containerClassName="mr-2 w-38 ml-2"
      autoFocus
      maxLength={25}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === "Escape") {
          showInput(false);
        }
        if (event.key === "Enter") {
          handleTabUpdate();
        }
      }}
    />
  );
};

export const DocumentHeader = ({
  renderTabs = true,
  renderOptions = true,
  renderToolbar = true,
  readOnly = false,
  editorRef,
  renderResubscribeCta,
  isCommentingEnabled,
  isLinkEditMode,
  setIsLinkEditMode = () => {},
}: {
  renderTabs?: boolean;
  renderOptions?: boolean;
  renderToolbar?: boolean;
  readOnly?: boolean;
  editorRef?: React.MutableRefObject<null>;
  renderResubscribeCta?: boolean;
  isCommentingEnabled?: boolean;
  isLinkEditMode?: boolean;
  setIsLinkEditMode?: (value: boolean) => void;
}) => {
  const { document: fraseDocument } = useDocumentStore();
  const { editor, setEditor } = useEditorStore();
  const { user } = useAuth();
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
  });
  const isDocumentRoute = useIsDocumentRoute();
  const [activeTab, setActiveTab] = useState(0);
  const [newTabName, setNewTabName] = useState("");
  const [isAddingTab, setIsAddingTab] = useState(false);
  const [lastEditedTime, setLastEditedTime] = useState(
    fraseDocument.lastEdited
  );
  const { isNavOpen } = useNavigationStore();

  const handleAddTab = useCallback(async () => {
    if (newTabName !== "") {
      const newTab = { name: newTabName, html: "", title: newTabName };
      const updatedDocument = {
        ...fraseDocument,
        text: [...fraseDocument.text, newTab],
      };
      await updateDocumentMutation.mutateAsync(updatedDocument);
      setNewTabName("");
      setIsAddingTab(false);

      handleTabClick(updatedDocument.text.length - 1);
    }
  }, [newTabName, fraseDocument, updateDocumentMutation]);

  const handleRenameTab = useCallback(
    async (index, newName) => {
      const updatedTab = { ...fraseDocument.text[index], name: newName };
      const updatedDocument = {
        ...fraseDocument,
        text: fraseDocument.text.map((tab, i) =>
          i === index ? updatedTab : tab
        ),
      };
      await updateDocumentMutation.mutateAsync(updatedDocument);
    },
    [fraseDocument, updateDocumentMutation]
  );

  const handleDeleteTab = useCallback(
    async (index) => {
      const updatedDocument = {
        ...fraseDocument,
        text: fraseDocument.text.filter((_, i) => i !== index),
      };
      await updateDocumentMutation.mutateAsync(updatedDocument);

      // Determine the new active tab index
      let newActiveTabIndex = activeTab;
      if (index === activeTab && activeTab > 0) {
        // If the active tab is deleted, activate the previous tab
        newActiveTabIndex = activeTab - 1;
      } else if (index < activeTab) {
        // If a tab before the active tab is deleted, shift the active tab index left
        newActiveTabIndex = activeTab - 1;
      }

      setActiveTab(newActiveTabIndex);
      setEditor((editorStore) => {
        editorStore.activeTabIndex = newActiveTabIndex;
      });
    },
    [fraseDocument, updateDocumentMutation, activeTab]
  );

  const handleTabClick = useCallback((index) => {
    setActiveTab(index);
    setEditor((editorStore) => {
      editorStore.activeTabIndex = index;
    });
  }, []);

  useEffect(() => {
    setLastEditedTime(fraseDocument.lastEdited);
  }, [fraseDocument.lastEdited]);

  useEffect(() => {
    setActiveTab(0);
    setEditor((editorStore) => {
      editorStore.activeTabIndex = 0;
    });
  }, [fraseDocument.hash]);

  useEffect(() => {
    setActiveTab(editor.activeTabIndex);
  }, [editor.activeTabIndex]);

  const renderTabsDesktop = useMemo(() => {
    if (Array.isArray(fraseDocument.text)) {
      return fraseDocument.text.map((tab, index) => {
        return (
          <DocumentTab
            readOnly={readOnly}
            key={index}
            {...tab}
            index={index}
            isLast={fraseDocument.text.length - 1 === 0}
            isActive={index === activeTab}
            onClick={() => handleTabClick(index)}
            handleRenameTab={handleRenameTab}
            handleDeleteTab={() => handleDeleteTab(index)}
          />
        );
      });
    }
    return null;
  }, [fraseDocument.text, activeTab]);

  const handleTitleUpdate = (e, title, doc) => {
    const newTitle = e.currentTarget.innerText.trim();
    if (newTitle === title || newTitle === "") return;

    const updatedDocument = {
      ...doc,
      metadata: {
        ...doc.metadata,
        name: newTitle,
      },
    };
    updateDocumentMutation.mutateAsync(updatedDocument);
  };

  const initialActiveTab = useMemo(() => {
    const metadataActiveTabIndex = fraseDocument.metadata?.active_tab_index;
    const validIndex =
      typeof metadataActiveTabIndex === "number" &&
      metadataActiveTabIndex >= 0 &&
      metadataActiveTabIndex < fraseDocument.text.length;

    return validIndex ? metadataActiveTabIndex : 0;
  }, [fraseDocument.hash]);

  useEffect(() => {
    setActiveTab(initialActiveTab);
    setEditor((editorStore) => {
      editorStore.activeTabIndex = initialActiveTab;
    });
  }, [initialActiveTab, setEditor]);

  return (
    <div className="@container">
      <div
        className={cn(
          "flex flex-col px-4 pt-4 justify-between max-w-full w-full dark:shadow-md bg-white dark:bg-zinc-900 align-middle border-b border-b-zinc-200 dark:border-b-zinc-800 print:hidden overflow-hidden",
          !renderTabs && "pb-4"
        )}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center w-full space-x-2 pl-3">
            {!user && readOnly && (
              <div className="flex items-center space-x-2 mt-2">
                <TbBolt className="h-3.5 w-3.5 text-amber-400 fill-amber-400" />
                <p className="text-xs text-zinc-600 dark:text-zinc-400 flex items-center">
                  Powered by
                  <Logo
                    className="h-3.5 hover:opacity-80 hover:underline"
                    textClassName="text-base ml-1"
                    renderLogo={false}
                    url="https://www.frase.io/?utm_source=frase_content_poweredby"
                  />
                </p>
              </div>
            )}
            {!readOnly && !renderResubscribeCta && !isNavOpen && <Navigation />}
            {!readOnly && (
              <p
                contentEditable={!readOnly}
                suppressContentEditableWarning={true}
                spellCheck={false}
                className="transition-all text-sm font-medium text-zinc-900 dark:text-white focus:rounded-sm focus:outline-[1.5px] focus:outline-emerald-600 dark:focus:rounded-sm dark:focus:outline-[2px] dark:focus:outline-emerald-600 dark:ring-none dark:border-none dark:ring-0 dark:ring-offset-0 dark:focus:ring-offset-zinc-900 dark:focus:ring-emerald-600 dark:focus:ring-opacity-20 dark:focus:ring-offset-opacity-20 dark:shadow-none ring-none border-none bg-transparent focus:outline-none truncate max-w-[200px] mr-4"
                onBlur={(e) =>
                  handleTitleUpdate(
                    e,
                    fraseDocument.metadata?.name,
                    fraseDocument
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleTitleUpdate(
                      e,
                      fraseDocument.metadata?.name,
                      fraseDocument
                    );
                    e.currentTarget.blur();
                  }
                }}
                onInput={(e) => {
                  if (e.currentTarget.innerText.length > 20) {
                    e.preventDefault();
                  }
                }}
              >
                {fraseDocument.metadata?.name || fraseDocument.query}
              </p>
            )}
            {renderTabs && (
              <div className="flex flex-row space-x-2 items-center overflow-x-hidden flex-grow">
                {renderTabsDesktop}

                {!isAddingTab && !readOnly && (
                  <Button
                    buttonIcon={
                      <TbPlus
                        style={{
                          strokeWidth: 2.5,
                        }}
                      />
                    }
                    tooltipContent="Add Tab"
                    className="z-[0]"
                    size="xs"
                    onClick={() => setIsAddingTab(true)}
                    variant="buttonIcon"
                  />
                )}

                {isAddingTab && (
                  <TabInput
                    showInput={setIsAddingTab}
                    setNewTabName={setNewTabName}
                    newTabName={newTabName}
                    handleTabUpdate={handleAddTab}
                  />
                )}
              </div>
            )}
          </div>
          {isDocumentRoute && !readOnly && (
            <div className="flex items-center space-x-2">
              {renderTabs && (
                <span className="text-2xs text-zinc-400 flex-shrink truncate font-medium hidden @[46rem]:flex">
                  {DateFormat(lastEditedTime).replace("in ", "")}
                </span>
              )}
              {renderOptions && (
                <>
                  <EditorOptionsMenu type="document" editorRef={editorRef} />
                  <ShareMenu
                    type="document"
                    previewHash={fraseDocument.doc_preview_hash}
                  />
                </>
              )}
              {renderTabs && <SidebarToggle />}
            </div>
          )}
          {readOnly && (
            <div className="flex items-center space-x-2 mt-2">
              <TbAlertCircleFilled className="h-3.5 w-3.5 text-amber-500" />
              <p className="text-xs text-zinc-600 dark:text-zinc-400">
                This document by {fraseDocument.doc_owner_name || "Unknown"} is
                read only.{" "}
                {renderResubscribeCta && (
                  <>
                    <Link to="/app/settings/plans" className="underline">
                      Resubscribe
                    </Link>{" "}
                    to enable editing.
                  </>
                )}
                {!user && readOnly && (
                  <>
                    <Link to="/register" className="underline">
                      Sign up
                    </Link>{" "}
                    for free to unlock editing & commenting.
                  </>
                )}
              </p>
            </div>
          )}
        </div>
        {!readOnly && renderToolbar && (
          <div className="flex-grow">
            <ToolbarPlugin
              isCommentingEnabled={isCommentingEnabled}
              setIsLinkEditMode={setIsLinkEditMode}
            />
          </div>
        )}
      </div>
    </div>
  );
};
