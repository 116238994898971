import React, { useMemo } from "react";
import { useFps } from "react-fps";

interface ComponentProps {
  width?: number;
  height?: number;
  top?: number | string;
  left?: number | string;
  bottom?: number | string;
  right?: number | string;
}

const FpsView: React.FC<ComponentProps> = () => {
  const { fps, avgFps, currentFps } = useFps(Math.floor(80 / 6));

  const fpsBars = useMemo(
    () =>
      fps.map((val, i) => (
        <div
          key={i}
          className="bg-emerald-500"
          style={{
            height: `${(val / 60) * 100}%`,
            maxHeight: "100%",
            width: 5,
            marginLeft: 1,
          }}
        />
      )),
    [fps]
  );

  return (
    <div className="flex items-center justify-center bg-zinc-50 dark:bg-zinc-900 text-xs rounded-md">
      <span className="text-zinc-600 dark:text-zinc-400 font-medium text-2xs">
        FPS: {currentFps} ({Math.floor(Number(avgFps))} Avg)
      </span>
      <div className="flex ml-2 h-6 w-20 bg-zinc-200 dark:bg-zinc-700 px-1 rotate-180">
        {fpsBars}
      </div>
    </div>
  );
};

export default FpsView;
