import { useDocumentStore } from "@/stores/document";
import { useSerpStore } from "@/stores/serp";
import { useTopicsStore } from "@/stores/topics";
import { debounce } from "lodash";
import React, { useMemo } from "react";
import TopicCompetitors from "./TopicCompetitors";
import TopicDetail from "./TopicDetail";
import TopicList from "./TopicList";

export const TopicScores = React.memo(({ panelWidth }) => {
  const { document: fraseDocument } = useDocumentStore();
  const docId = fraseDocument.id;
  const { serp } = useSerpStore();
  const currentSerp = serp[docId] || {
    serpLoaded: false,
  };
  const { serpLoaded } = currentSerp;
  const { topics: topicsStore, setTopics } = useTopicsStore();
  const { articles } = serp[docId] || {
    articles: [],
  };
  const { topics, clusters, score, avgScore, selectedStatus, selectedType } =
    topicsStore[docId] || {
      topics: [],
      clusters: [],
      score: 0,
      avgScore: 0,
      selectedStatus: "all",
      selectedType: "longTail",
    };
  const [filteredTopics, setFilteredTopics] = React.useState(topics);
  const [selectedTopic, setSelectedTopic] = React.useState(null);
  const [competitorsVisible, setCompetitorsVisible] = React.useState(false);

  const updateFilteredTopics = useMemo(
    () =>
      debounce(() => {
        if (selectedType === "clusters") {
          setFilteredTopics(clusters);
          return;
        }

        const blacklist = fraseDocument.metadata?.blacklist_topics || {};
        const topics = topicsStore[docId]?.topics || [];
        const filteredTopics = topics.filter(
          (topic) => !blacklist[topic.entity]
        );

        setFilteredTopics(filteredTopics);
      }, 300),
    [
      clusters,
      topicsStore,
      docId,
      selectedType,
      fraseDocument.metadata?.blacklist_topics,
    ]
  );

  React.useEffect(() => {
    updateFilteredTopics();
    return () => {
      updateFilteredTopics.cancel();
    };
  }, [updateFilteredTopics]);

  const handleFilterChange = (value) => {
    const blacklist = fraseDocument.metadata?.blacklist_topics || {};
    const filtered = topics.filter(
      (topic) =>
        !blacklist[topic.entity] &&
        topic.entity.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTopics(filtered);
  };

  const handleTypeChange = (value) => {
    setTopics(docId, {
      ...topicsStore[docId],
      selectedType: value,
    });
  };

  const handleStatusChange = (value) => {
    setTopics(docId, {
      ...topicsStore[docId],
      selectedStatus: value,
    });
  };

  if (competitorsVisible) {
    return <TopicCompetitors setCompetitorsVisible={setCompetitorsVisible} />;
  }

  return (
    <div className="h-full">
      {!selectedTopic ? (
        <TopicList
          setCompetitorsVisible={setCompetitorsVisible}
          onFilterChange={handleFilterChange}
          filteredTopics={filteredTopics || []}
          setSelectedTopic={setSelectedTopic}
          score={score}
          avgScore={avgScore}
          selectedType={selectedType}
          setSelectedType={handleTypeChange}
          selectedStatus={selectedStatus}
          setSelectedStatus={handleStatusChange}
          panelWidth={panelWidth}
          document={fraseDocument}
        />
      ) : (
        <TopicDetail
          setSelectedTopic={setSelectedTopic}
          selectedTopic={selectedTopic}
          articles={articles}
        />
      )}
    </div>
  );
});
