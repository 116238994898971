import { Input } from "@/components/Elements/Input";
import { Label } from "@/components/Elements/Label";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useUser } from "@/features/auth/api/getUser";
import { axios } from "@/lib/axios";
import { useNotificationStore } from "@/stores/notifications";
import { cn } from "@/utils/style";
import { useEffect, useRef, useState } from "react";
import { TbCircleFilled } from "react-icons/tb";
import { useQueryClient } from "react-query";
import { Button, Popover, PopoverContent, PopoverTrigger } from "../Elements";

// Constants for local storage keys
const MOCKED_USER_KEY = "dev_mocked_user_data";
const MOCKED_SUBSCRIPTION_KEY = "dev_mocked_subscription_key";

// Load mocked data from localStorage
const loadMockedData = (key: string) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : {};
  } catch (e) {
    console.error("Error loading mocked data:", e);
    return {};
  }
};

// Save mocked data to localStorage
const saveMockedData = (key: string, data: Record<string, any>) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.error("Error saving mocked data:", e);
  }
};

// Helper function to format value for display
const formatValue = (value: any) => {
  if (value === null || value === undefined) return "N/A";
  if (typeof value === "boolean") return value ? "True" : "False";
  if (typeof value === "object") return JSON.stringify(value);
  return String(value);
};

// Format date from timestamp
const formatDate = (timestamp: number | null | undefined) => {
  if (!timestamp) return "N/A";
  return new Date(timestamp).toLocaleDateString();
};

// Helper to convert keys to display names
const getDisplayName = (key: string) => {
  return key
    .replace(/([A-Z])/g, " $1") // Add space before capital letters
    .replace(/_/g, " ") // Replace underscores with spaces
    .split(" ") // Split into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter
    .join(" "); // Join back with spaces
};

type FieldRowProps = {
  field: any;
  section: "user" | "subscription";
  editMode: boolean;
  isMocked: (section: "user" | "subscription", key: string) => boolean;
  getValue: (
    section: "user" | "subscription",
    key: string,
    accessor: () => any
  ) => any;
  updateDraftData: (
    section: "user" | "subscription",
    key: string,
    value: any
  ) => void;
  fieldRenderKey: number;
};

// Extract field component to fix Hooks usage
const FieldRow = ({
  field,
  section,
  editMode,
  isMocked,
  getValue,
  updateDraftData,
  fieldRenderKey,
}: FieldRowProps) => {
  const isMockedField = isMocked(section, field.key);
  const fieldValue = getValue(section, field.key, field.accessor);

  // Use a local state for smooth typing in this input field
  const [localValue, setLocalValue] = useState(() => formatValue(fieldValue));

  // Reset local value when the field value changes (from outside, not from typing)
  useEffect(() => {
    setLocalValue(formatValue(fieldValue));
  }, [fieldValue]);

  return (
    <div
      key={`${field.key}-${fieldRenderKey}`}
      className="grid grid-cols-[120px_1fr] items-center gap-2 w-full"
    >
      <Label htmlFor={field.key} className="text-xs font-medium truncate">
        {field.label || getDisplayName(field.key)}
      </Label>
      <div className="relative w-full">
        <Input
          id={field.key}
          value={localValue}
          onChange={(value) => {
            // Only update local state during typing for a smooth experience
            if (editMode) {
              // Properly handle the value, which could be a React event or a direct string
              const processedValue =
                typeof value === "object" && value !== null && "target" in value
                  ? (value as React.ChangeEvent<HTMLInputElement>).target.value
                  : (value as string);
              setLocalValue(processedValue);
            }
          }}
          // Update draft data when the input loses focus
          onBlur={() => {
            if (editMode) {
              updateDraftData(section, field.key, localValue);
            }
          }}
          className={`h-8 text-xs font-medium w-full ${
            isMockedField ? "border-amber-500 dark:border-amber-400" : ""
          }`}
          readOnly={!editMode}
        />
        {isMockedField && (
          <div
            className="absolute top-[-6px] right-[-6px] bg-amber-500 dark:bg-amber-400 text-white rounded-full w-5 h-5 flex items-center justify-center text-[10px] font-bold shadow-sm"
            title="Mocked value that will persist across page loads"
          >
            M
          </div>
        )}
      </div>
    </div>
  );
};

export const UserInfo = () => {
  const { data: user, isLoading: userLoading, error: userError } = useUser();
  const {
    data: subscription,
    isLoading: subLoading,
    error: subError,
  } = useSubscription();
  const queryClient = useQueryClient();

  // Security check - only allow mocking in non-production environments
  const env = import.meta.env.VITE_REACT_APP_ENVIRONMENT || "";
  const isProductionEnv = env.toLowerCase() === "production";

  // Ref to track if interceptors are set up
  const interceptorsRef = useRef<{
    request: number | null;
    response: number | null;
  }>({
    request: null,
    response: null,
  });

  // State to track mocked values and edit mode
  // Use state to track when field values should be re-rendered
  const [fieldRenderKey, setFieldRenderKey] = useState(Date.now());

  // State for persisted mocked data
  const [mockedUserData, setMockedUserData] = useState<Record<string, any>>(
    () => loadMockedData(MOCKED_USER_KEY)
  );
  const [mockedSubscriptionData, setMockedSubscriptionData] = useState<
    Record<string, any>
  >(() => loadMockedData(MOCKED_SUBSCRIPTION_KEY));

  // State for draft values that are only applied when saving
  const [draftUserData, setDraftUserData] = useState<Record<string, any>>(() =>
    loadMockedData(MOCKED_USER_KEY)
  );
  const [draftSubscriptionData, setDraftSubscriptionData] = useState<
    Record<string, any>
  >(() => loadMockedData(MOCKED_SUBSCRIPTION_KEY));

  const [editMode, setEditMode] = useState(false);

  // Apply mocked data to React Query cache if user and subscription are already loaded
  useEffect(() => {
    // Skip applying mocked data if user or subscription data isn't loaded yet
    // or if in production environment
    if (!user || !subscription || isProductionEnv) return;

    const hasMockedUserData = Object.keys(mockedUserData).length > 0;
    const hasMockedSubscriptionData =
      Object.keys(mockedSubscriptionData).length > 0;

    if (hasMockedUserData) {
      console.log(
        "⚡ Updating React Query cache with mocked user data on load"
      );
      const modifiedUser = { ...user };

      // Apply our mocked values
      Object.entries(mockedUserData).forEach(([key, value]) => {
        if (key === "id" || key === "orgId") {
          modifiedUser[key] = parseInt(String(value), 10) || 0;
        } else if (key === "legacy") {
          modifiedUser[key] =
            value === "True" || value === "true" || value === true;
        } else {
          modifiedUser[key] = value;
        }
      });

      // Update the React Query cache directly
      queryClient.setQueryData("user", modifiedUser);
    }

    if (hasMockedSubscriptionData) {
      console.log(
        "⚡ Updating React Query cache with mocked subscription data on load"
      );
      const modifiedSubscription = { ...subscription };

      // Apply our mocked values
      Object.entries(mockedSubscriptionData).forEach(([key, value]) => {
        if (key === "remaining_documents" || key === "documents_purchased") {
          modifiedSubscription[key] = parseInt(String(value), 10) || 0;
        } else if (key === "start_date") {
          try {
            const date = new Date(value as string);
            modifiedSubscription.subscription_start_date = date.getTime();
          } catch (e) {
            // Keep original if parsing fails
          }
        } else if (key === "renewal_date") {
          try {
            const date = new Date(value as string);
            modifiedSubscription.subscription_renewal_date = date.getTime();
          } catch (e) {
            // Keep original if parsing fails
          }
        } else if (key === "add_on") {
          modifiedSubscription[key] =
            value === "True" || value === "true" || value === true;
        } else {
          modifiedSubscription[key] = value;
        }
      });

      // Update the React Query cache directly
      queryClient.setQueryData("subscription", modifiedSubscription);
    }
  }, [user, subscription, mockedUserData, mockedSubscriptionData, queryClient]);

  // Set up axios interceptors for future network requests
  useEffect(() => {
    // Clean up any existing interceptors first to prevent duplicates
    if (interceptorsRef.current.request) {
      axios.interceptors.request.eject(interceptorsRef.current.request);
      axios.interceptors.response.eject(interceptorsRef.current.response!);
      interceptorsRef.current = { request: null, response: null };
      console.log("🧹 Cleaned up existing interceptors before setup");
    }

    // Security check - don't set up interceptors in production
    if (isProductionEnv) return;

    // Check if we have mocked data to apply
    const hasMockedData =
      Object.keys(mockedUserData).length > 0 ||
      Object.keys(mockedSubscriptionData).length > 0;

    if (hasMockedData) {
      console.log(
        "🔧 Setting up interceptors for future requests with mocked data",
        {
          userDataKeys: Object.keys(mockedUserData),
          subscriptionDataKeys: Object.keys(mockedSubscriptionData),
        }
      );

      // Set up request interceptor
      interceptorsRef.current.request = axios.interceptors.request.use(
        (config) => {
          // Check if this is a user or subscription request based on URL
          if (config.url === "/api/testJwtToken") {
            // This is a request for user data
            const userIntercepted = Object.keys(mockedUserData).length > 0;
            if (userIntercepted) {
              console.log(
                "🔶 Intercepted user request with mocked data",
                config.url
              );
            }
          } else if (config.url === "/stripe/subscription_info") {
            // This is a request for subscription data
            const subscriptionIntercepted =
              Object.keys(mockedSubscriptionData).length > 0;
            if (subscriptionIntercepted) {
              console.log(
                "🔶 Intercepted subscription request with mocked data",
                config.url
              );
            }
          }
          return config;
        },
        (error) => Promise.reject(error)
      );

      // Set up response interceptor for future network requests
      interceptorsRef.current.response = axios.interceptors.response.use(
        (response) => {
          // Log all responses to help with debugging
          console.log(
            "📡 Response interceptor running for URL:",
            response.config?.url || "Unknown URL"
          );

          // Check if this is a user or subscription response based on URL
          if (
            response.config?.url === "/api/testJwtToken" &&
            Object.keys(mockedUserData).length > 0
          ) {
            // This is a response with user data - modify with our mocked data
            const modifiedUser = { ...response.data };

            // Apply our mocked values to the response
            Object.entries(mockedUserData).forEach(([key, value]) => {
              if (key === "id" || key === "orgId") {
                modifiedUser[key] = parseInt(String(value), 10) || 0;
              } else if (key === "legacy") {
                modifiedUser[key] =
                  value === "True" || value === "true" || value === true;
              } else {
                modifiedUser[key] = value;
              }
            });

            response.data = modifiedUser;
            console.log("🟠 Returning mocked user data:", modifiedUser);

            // Also update React Query cache with this data
            queryClient.setQueryData("user", modifiedUser);
          } else if (
            response.config?.url === "/stripe/subscription_info" &&
            Object.keys(mockedSubscriptionData).length > 0
          ) {
            // This is a response with subscription data - modify with our mocked data
            const modifiedSubscription = { ...response.data };

            // Apply our mocked values to the response
            Object.entries(mockedSubscriptionData).forEach(([key, value]) => {
              if (key === "start_date") {
                try {
                  const date = new Date(value as string);
                  modifiedSubscription.subscription_start_date = date.getTime();
                } catch (e) {
                  // Keep original if parsing fails
                }
              } else if (key === "renewal_date") {
                try {
                  const date = new Date(value as string);
                  modifiedSubscription.subscription_renewal_date =
                    date.getTime();
                } catch (e) {
                  // Keep original if parsing fails
                }
              } else if (key === "add_on") {
                modifiedSubscription[key] =
                  value === "True" || value === "true" || value === true;
              } else {
                modifiedSubscription[key] = value;
              }
            });

            response.data = modifiedSubscription;
            console.log(
              "🟢 Returning mocked subscription data:",
              modifiedSubscription
            );

            // Also update React Query cache with this data
            queryClient.setQueryData("subscription", modifiedSubscription);
          }

          return response;
        },
        (error) => Promise.reject(error)
      );
    }

    // We don't clean up interceptors on unmount, only when resetting values
    return () => {};
  }, [mockedUserData, mockedSubscriptionData, queryClient]);

  // Function to update draft data (doesn't persist until Save is clicked)
  const updateDraftData = (
    section: "user" | "subscription",
    key: string,
    value: any
  ) => {
    try {
      const processedValue =
        typeof value === "object" && value.target ? value.target.value : value;

      if (section === "user") {
        setDraftUserData((prev) => ({
          ...prev,
          [key]: processedValue,
        }));
      } else {
        setDraftSubscriptionData((prev) => ({
          ...prev,
          [key]: processedValue,
        }));
      }
    } catch (error) {
      console.error("Error updating draft data:", error);
    }
  };

  // Function to update the actual mocked data (used internally)
  const updateMockedData = (
    section: "user" | "subscription",
    key: string,
    value: any
  ) => {
    if (section === "user") {
      setMockedUserData((prev) => {
        const updated = { ...prev, [key]: value };
        return updated;
      });
    } else {
      setMockedSubscriptionData((prev) => {
        const updated = { ...prev, [key]: value };
        return updated;
      });
    }
  };

  // Access notification store
  const { addNotification } = useNotificationStore();

  // Function to save mocked data to localStorage and set up interceptors
  const applyMockedData = () => {
    console.log("🔄 Applying mocked data...");

    // Apply draft data to the actual mocked data using updateMockedData
    Object.entries(draftUserData).forEach(([key, value]) => {
      updateMockedData("user", key, value);
    });

    Object.entries(draftSubscriptionData).forEach(([key, value]) => {
      updateMockedData("subscription", key, value);
    });

    const hasMockedUserData = Object.keys(draftUserData).length > 0;
    const hasMockedSubscriptionData =
      Object.keys(draftSubscriptionData).length > 0;

    if (hasMockedUserData) {
      console.log("💾 Saving user data to localStorage:", draftUserData);
      // Save user data to localStorage
      saveMockedData(MOCKED_USER_KEY, draftUserData);
    }

    if (hasMockedSubscriptionData) {
      console.log(
        "💾 Saving subscription data to localStorage:",
        draftSubscriptionData
      );
      // Save subscription data to localStorage
      saveMockedData(MOCKED_SUBSCRIPTION_KEY, draftSubscriptionData);
    }

    // Clean up any existing interceptors to ensure we start fresh
    if (interceptorsRef.current.request) {
      console.log(
        "🧹 Cleaning up existing interceptors before applying new ones"
      );
      try {
        axios.interceptors.request.eject(interceptorsRef.current.request);
        axios.interceptors.response.eject(interceptorsRef.current.response!);
        interceptorsRef.current = { request: null, response: null };
      } catch (error) {
        console.error("❌ Error cleaning up interceptors:", error);
      }
    }

    // Directly update the React Query cache with our mocked data
    if (hasMockedUserData && user) {
      console.log("⚡ Directly updating React Query cache for user");
      const modifiedUser = { ...user };

      // Apply our mocked values to the cache
      Object.entries(mockedUserData).forEach(([key, value]) => {
        if (key === "id" || key === "orgId") {
          modifiedUser[key] = parseInt(String(value), 10) || 0;
        } else if (key === "legacy") {
          modifiedUser[key] =
            value === "True" || value === "true" || value === true;
        } else {
          modifiedUser[key] = value;
        }
      });

      // Update the React Query cache directly
      queryClient.setQueryData("user", modifiedUser);
    }

    if (hasMockedSubscriptionData && subscription) {
      console.log("⚡ Directly updating React Query cache for subscription");
      const modifiedSubscription = { ...subscription };

      // Apply our mocked values to the cache
      Object.entries(mockedSubscriptionData).forEach(([key, value]) => {
        if (key === "start_date") {
          try {
            const date = new Date(value as string);
            modifiedSubscription.subscription_start_date = date.getTime();
          } catch (e) {
            // Keep original if parsing fails
          }
        } else if (key === "renewal_date") {
          try {
            const date = new Date(value as string);
            modifiedSubscription.subscription_renewal_date = date.getTime();
          } catch (e) {
            // Keep original if parsing fails
          }
        } else if (key === "add_on") {
          modifiedSubscription[key] =
            value === "True" || value === "true" || value === true;
        } else {
          modifiedSubscription[key] = value;
        }
      });

      // Update the React Query cache directly
      queryClient.setQueryData("subscription", modifiedSubscription);
    }

    // The useEffect will set up the interceptors based on the state
    // for future requests, but we've already updated the cache for
    // immediate visual feedback
    console.log("🔄 Setting up interceptors for future requests");

    // Show a success notification
    addNotification({
      type: "success",
      title: "Mocked Values Applied",
      message:
        "Your custom values will persist across page refreshes until reset.",
    });

    // Turn off edit mode
    setEditMode(false);

    console.log("✅ Mocked data applied successfully");
  };

  // Function to reset mocked data
  const resetMockedData = () => {
    console.log("🔄 Starting reset...");

    // Clean up interceptors when we reset - do this FIRST
    if (interceptorsRef.current.request) {
      console.log("🧹 Cleaning up axios interceptors on reset");
      try {
        axios.interceptors.request.eject(interceptorsRef.current.request);
        axios.interceptors.response.eject(interceptorsRef.current.response!);
        interceptorsRef.current = { request: null, response: null };
        console.log("✅ Successfully cleaned up interceptors");
      } catch (error) {
        console.error("❌ Error cleaning up interceptors:", error);
      }
    } else {
      console.log("ℹ️ No interceptors to clean up");
    }

    // Clear localStorage SECOND - this ensures our persistence is gone
    try {
      localStorage.removeItem(MOCKED_USER_KEY);
      localStorage.removeItem(MOCKED_SUBSCRIPTION_KEY);
      console.log("✅ Cleared localStorage");
    } catch (error) {
      console.error("❌ Error clearing localStorage:", error);
    }

    // Clear state THIRD - this will trigger our useEffect
    // which should now NOT set up new interceptors because there's no data
    setMockedUserData({});
    setMockedSubscriptionData({});
    // Also clear draft data
    setDraftUserData({});
    setDraftSubscriptionData({});
    console.log("✅ Cleared mock data state");

    // Reset the React Query cache or fetch fresh data
    try {
      // Either force a refetch by invalidating
      queryClient.invalidateQueries("user");
      queryClient.invalidateQueries("subscription");
      console.log("✅ Invalidated queries to force reload");

      // Or for immediate visual feedback, we could also
      // immediately refresh the cache with current server data
      queryClient.refetchQueries("user");
      queryClient.refetchQueries("subscription");
      console.log("✅ Triggered immediate refetch of data");
    } catch (error) {
      console.error("❌ Error resetting React Query cache:", error);
    }

    // Set edit mode off
    setEditMode(false);

    // Force re-render of fields with a new key
    setFieldRenderKey(Date.now());
    console.log("🔄 Forced re-render of fields with new key after reset");

    // Show a success notification
    addNotification({
      type: "success",
      title: "Mocked Values Reset",
      message: "Original values have been restored.",
    });

    console.log("✅ Reset completed");
  };

  const isLoading = userLoading || subLoading;
  const hasError = userError || subError;

  // Always define hooks before any early returns
  // Ensure all conditional UI rendering comes after all hooks are defined

  // Define the user fields we want to display
  const userFields = user
    ? [
        { key: "orgId", accessor: () => user.orgId },
        { key: "id", accessor: () => user.id },
        { key: "fullName", accessor: () => user.fullName },
        { key: "username", label: "Email", accessor: () => user.username },
        {
          key: "org_role_desc",
          label: "Org Role",
          accessor: () => user.org_role_desc,
        },
        { key: "legacy", accessor: () => user.legacy },
      ]
    : [];

  // Define subscription fields we want to display
  const subscriptionFields = subscription
    ? [
        { key: "plan", accessor: () => subscription.plan },
        { key: "status", accessor: () => subscription.status },
        { key: "billing_cycle", accessor: () => subscription.billing_cycle },
        {
          key: "start_date",
          label: "Start Date",
          accessor: () => formatDate(subscription.subscription_start_date),
        },
        {
          key: "renewal_date",
          label: "Renewal Date",
          accessor: () => formatDate(subscription.subscription_renewal_date),
        },
        { key: "add_on", label: "Add-on", accessor: () => subscription.add_on },
      ]
    : [];

  // Update field render key whenever data changes
  useEffect(() => {
    console.log("🔄 Updating field render key due to data change");
    setFieldRenderKey(Date.now());
  }, [user, subscription, draftUserData, draftSubscriptionData]);

  if (isLoading) {
    return (
      <Button size="2xs" variant="text" disabled>
        Loading user...
      </Button>
    );
  }

  if (hasError || !user) {
    return (
      <Button size="2xs" variant="text" disabled>
        User not found
      </Button>
    );
  }

  // Check if field has been mocked (use draft data for UI display)
  const isMocked = (section: "user" | "subscription", key: string) => {
    return section === "user"
      ? draftUserData.hasOwnProperty(key)
      : draftSubscriptionData.hasOwnProperty(key);
  };

  // Get value for a field (draft mocked or original)
  const getValue = (
    section: "user" | "subscription",
    key: string,
    originalAccessor: () => any
  ) => {
    if (section === "user" && draftUserData.hasOwnProperty(key)) {
      return draftUserData[key];
    } else if (
      section === "subscription" &&
      draftSubscriptionData.hasOwnProperty(key)
    ) {
      return draftSubscriptionData[key];
    }
    return originalAccessor();
  };

  // Helper function to render a field - delegates to the FieldRow component
  const renderField = (field: any, section: "user" | "subscription") => {
    return (
      <FieldRow
        key={field.key}
        field={field}
        section={section}
        editMode={editMode}
        isMocked={isMocked}
        getValue={getValue}
        updateDraftData={updateDraftData}
        fieldRenderKey={fieldRenderKey}
      />
    );
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          size="2xs"
          variant="text"
          className={cn(
            (Object.keys(mockedUserData).length > 0 ||
              Object.keys(mockedSubscriptionData).length > 0) &&
              "text-yellow-600 dark:text-yellow-400"
          )}
          startIcon={
            Object.keys(mockedUserData).length > 0 ||
            Object.keys(mockedSubscriptionData).length > 0 ? (
              <TbCircleFilled className="animate-pulse w-1.5 h-1.5" />
            ) : null
          }
        >
          User Info
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-96 p-4 max-h-[calc(100vh-100px)] overflow-y-auto">
        <div className="grid gap-4 w-full">
          <div className="grid gap-2 w-full">
            {/* Header with controls */}
            <div className="flex justify-between items-center">
              <h5 className="text-xs font-semibold text-zinc-900 dark:text-blue-300">
                User Details
              </h5>
              <div className="flex gap-1">
                {!editMode && (
                  <Button
                    size="xs"
                    variant="outline"
                    onClick={() => setEditMode(!editMode)}
                    className="text-xs h-6 py-0"
                  >
                    Edit
                  </Button>
                )}
                {editMode && (
                  <>
                    <Button
                      size="xs"
                      variant="outline"
                      onClick={resetMockedData}
                      className="text-xs h-6 py-0"
                    >
                      Reset
                    </Button>
                    <Button
                      size="xs"
                      variant="primary"
                      onClick={applyMockedData}
                      className="text-xs h-6 py-0"
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </div>

            {editMode && (
              <div className="bg-amber-50 dark:bg-amber-900/20 p-2 rounded text-xs border border-amber-100 dark:border-amber-800">
                <p className="text-amber-700 dark:text-amber-300">
                  Edit mode: Modified fields will be mocked and persist across
                  page loads.
                </p>
              </div>
            )}

            {!editMode &&
              (Object.keys(mockedUserData).length > 0 ||
                Object.keys(mockedSubscriptionData).length > 0) && (
                <div className="bg-red-50 dark:bg-red-900/20 p-2 rounded text-xs flex items-center gap-2">
                  <div className="w-3 h-3 rounded-full bg-red-500 animate-pulse"></div>
                  <p className="text-red-700 dark:text-red-300">
                    Mock mode active: Some values are being intercepted and
                    replaced.
                  </p>
                </div>
              )}

            {/* User information section */}
            <div className="bg-blue-50 dark:bg-blue-900/20 p-3 rounded border border-blue-100 dark:border-blue-800">
              <div className="grid gap-2">
                {userFields.map((field) => renderField(field, "user"))}
              </div>
            </div>

            {/* Subscription information section */}
            {subscription && (
              <div className="grid gap-2 w-full mt-2">
                <h5 className="text-xs font-semibold text-zinc-900 dark:text-green-300">
                  Subscription Details
                </h5>
                <div className="bg-green-50 dark:bg-green-900/20 p-3 rounded border border-green-100 dark:border-green-800">
                  <div className="grid gap-2">
                    {subscriptionFields.map((field) =>
                      renderField(field, "subscription")
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
