import {
  ArticleMetadata,
  Input,
  PasteAssetButton,
} from "@/components/Elements";
import { Skeleton } from "@/components/Elements/Skeleton";
import useBriefFactory from "@/features/documents/utils/brief";
import {
  useHandlePasteCitation,
  useHandlePasteContent,
} from "@/features/documents/utils/pasting";
import { useDocumentStore } from "@/stores/document";
import { useSerpStore } from "@/stores/serp";
import { useEffect, useRef, useState } from "react";
import { VirtualizedList } from "./Questions";

function Statistics() {
  const { document } = useDocumentStore();
  const { serp } = useSerpStore();
  const docId = document.id;
  const currentSerp = serp[docId] || { processed_items: [] };
  const [filter, setFilter] = useState("");
  const [statistics, setStatistics] = useState([]);
  const { getSERPStatistics } = useBriefFactory();
  const handlePasteContent = useHandlePasteContent();
  const handlePasteCitation = useHandlePasteCitation();
  const parentRef = useRef(null);

  useEffect(() => {
    const stats = getSERPStatistics(currentSerp);
    setStatistics(stats);
  }, [currentSerp]);

  const filteredStats = statistics.filter((stat) =>
    stat.stat.toLowerCase().includes(filter.toLowerCase())
  );

  const isLoading = !statistics.length;

  const renderLoadingSkeleton = () =>
    Array.from({ length: 10 }).map((_, i) => (
      <div
        key={i}
        className="group mt-2 p-2 cursor-pointer relative items-center rounded-md bg-white transition-shadow hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-black/5 hover:bg-white"
      >
        <div className="absolute inset-0 rounded-md ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20" />
        <Skeleton className="w-full h-4" />
      </div>
    ));

  return (
    <div>
      <div className="flex items-center justify-between px-4  my-2">
        <Input
          value={filter}
          onChange={(value) => setFilter(value)}
          placeholder="Filter by keyword..."
          className="text-xs"
        />
      </div>
      <div ref={parentRef} className="space-y-2 pl-4 pr-1">
        {isLoading ? (
          renderLoadingSkeleton()
        ) : (
          <VirtualizedList
            items={filteredStats}
            parentRef={parentRef}
            renderItem={(stat, index) => (
              <div
                key={index}
                className="flex flex-col group/asset-text p-2 mb-2 cursor-pointer relative rounded-md ring-1 ring-inset ring-zinc-900/7.5 dark:ring-white/10 hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-zinc-50/5"
              >
                <div className="flex items-center justify-between my-1 pl-2">
                  <ArticleMetadata
                    url={stat.source.url}
                    urlHost={getSourceUrl(stat.source.url)}
                  />
                </div>

                <div className="flex">
                  <div className="flex flex-col w-full space-y-1 pl-2">
                    <div className="flex items-center justify-between">
                      <p className="transition-all text-2xs text-zinc-900 dark:text-white">
                        {stat.stat}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center ml-2">
                    <PasteAssetButton
                      handlePasteContent={handlePasteContent}
                      handlePasteCitation={handlePasteCitation}
                      headerText={stat.stat}
                      url={stat.source.url}
                      className="invisible group-hover/asset-text:visible"
                    />
                  </div>
                </div>
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
}

function getSourceUrl(url) {
  // Implement the logic to extract the domain from the URL
  return new URL(url).hostname;
}

export default Statistics;
