import { toast } from "@/hooks/useToast";
import { axios } from "@/lib/axios";
import storage from "@/utils/storage";
import { useNavigate } from "react-router-dom";
import { Notification as StoreNotification } from "src/stores/notifications";
import { create } from "zustand";

interface GSCTokenInfo {
  isGSCEnabled: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  authorizedSites: string[];
}

/**
 * Content Opportunities Store
 *
 * Manages state for the content opportunities feature, which helps users
 * monitor, analyze, and optimize their content based on search performance data.
 */
export interface ContentOpportunitiesState {
  // Core filter state
  siteUrl: string | null;

  // GSC integration state
  isInit: boolean;
  isInitiating: boolean;
  isGSCEnabled: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  domainLimit: number;
  authorizedSites: string[];
  gscSites: string[]; // All sites available from Google Search Console
  showSelectSiteDialog: boolean;
  showUpgradeToContinueDialog: boolean;
  isLoadingDisableSearchConsole: boolean;
  weeks: string;
  setWeeks: (weeks: string) => void;

  // Actions for filters
  setSiteUrl: (url: string | null) => void;
  resetFilters: () => void;

  // GSC integration actions
  init: () => Promise<void>;
  initGscData: () => Promise<void>;
  setIsInit: (isInit: boolean) => void;
  setIsGSCEnabled: (enabled: boolean) => void;
  setAccessToken: (token: string | null) => void;
  setRefreshToken: (token: string | null) => void;
  setDomainLimit: (newLimit: number) => void;
  setShowSelectSiteDialog: (show: boolean) => void;
  setShowUpgradeToContinueDialog: (show: boolean) => void;
  setIsLoadingDisableSearchConsole: (isLoading: boolean) => void;
  getSites: () => Promise<string[] | "error">;
  integrateGSC: (
    code: string,
    navigate: ReturnType<typeof useNavigate>,
    addNotification: (notification: Omit<StoreNotification, "id">) => void
  ) => Promise<void>;
  setAuthorizedSite: (site: string) => Promise<void>;
  disableSearchConsole: () => Promise<void>;
}

/**
 * Create zustand store for content opportunities
 *
 * This store manages the filters and settings for the content opportunities
 * feature, including site URLs, page URLs, and search queries.
 */
export const useContentOpportunitiesStore = create<ContentOpportunitiesState>(
  (set, get) => ({
    // Initial filter state
    siteUrl: null,

    // Initial GSC integration state
    isInit: false,
    isInitiating: false,
    isGSCEnabled: false,
    accessToken: null,
    refreshToken: null,
    domainLimit: 0,
    authorizedSites: [],
    gscSites: [], // All sites from Google Search Console
    showSelectSiteDialog: false,
    showUpgradeToContinueDialog: false,
    isLoadingDisableSearchConsole: false,
    weeks: "8",
    setWeeks: (weeks: string) => set({ weeks }),

    // Filter actions
    setSiteUrl: (url) => set({ siteUrl: url }),
    resetFilters: () =>
      set({
        siteUrl: null,
      }),

    // GSC integration actions
    setIsInit: (isInit) => set({ isInit }),
    setIsGSCEnabled: (enabled) => set({ isGSCEnabled: enabled }),
    setAccessToken: (token) => set({ accessToken: token }),
    setRefreshToken: (token) => set({ refreshToken: token }),
    setDomainLimit: (newLimit: number) => set({ domainLimit: newLimit }),
    setShowSelectSiteDialog: (show) => set({ showSelectSiteDialog: show }),
    setShowUpgradeToContinueDialog: (show) =>
      set({ showUpgradeToContinueDialog: show }),
    setIsLoadingDisableSearchConsole: (isLoading) =>
      set({ isLoadingDisableSearchConsole: isLoading }),
    getSites: async () => {
      const { accessToken } = get();
      try {
        const response: { siteUrl: string }[] = await axios.post(
          "/searchConsole/getSitesForUser",
          {
            accessToken,
          }
        );
        return response.map(({ siteUrl }) => siteUrl);
      } catch (error) {
        console.error("Error getting sites:", error);
        return "error";
      }
    },

    /**
     * Initialize the GSC integration
     * Check domain limits and GSC token information
     */
    init: async () => {
      const { isInitiating, isInit, getSites } = get();
      if (isInitiating || isInit) {
        return;
      }
      set({ isInitiating: true });

      // Get domain limits from the server
      const response = await axios.post("/searchConsole/getGscDomainsAllowed");
      // @ts-ignore
      const domains_allowed = response.domains_allowed;
      const domainLimit = domains_allowed === -1 ? Infinity : domains_allowed;

      set({ domainLimit });

      // If domain limit is 0, just return as upgrade is required
      if (domainLimit === 0) {
        set({ isInit: true, isInitiating: false });
        return;
      }

      // Get GSC token information
      const { isGSCEnabled, accessToken, refreshToken, authorizedSites } =
        await fetchGSCToken();
      set({ isGSCEnabled, accessToken, refreshToken, authorizedSites });

      if (!isGSCEnabled) {
        set({ isInit: true, isInitiating: false });
        return;
      }

      // Fetch all sites from Google Search Console
      const sites = await getSites();
      if (sites !== "error") {
        set({ gscSites: sites });

        // Handle the case where we need to prompt user to select a site
        // This happens when user has multiple GSC sites AND either:
        // 1. They don't have an authorizedSite, or
        // 2. Their authorizedSite isn't in their gscSites list
        if (sites.length > 1) {
          const needsToSelectSite =
            authorizedSites.length === 0 || !sites.includes(authorizedSites[0]);

          // If there are multiple sites and the domain limit is 1, show the select site dialog
          if (needsToSelectSite && domainLimit > 0) {
            set({ showSelectSiteDialog: true });
          }
          //If the user has only one site and the domain limit is 1, set the siteUrl to the only site
        } else if (sites.length === 1 && domainLimit > 0) { 
          const needsToSelectSite =
            authorizedSites.length === 0 || !sites.includes(authorizedSites[0]);
            if (needsToSelectSite && domainLimit > 0) {
              set({ siteUrl: sites[0] });              
            }
        }
      }

      // Handle case where user has more authorized sites than their plan allows
      if (authorizedSites.length > domainLimit && domainLimit > 0) {
        set({
          showUpgradeToContinueDialog: true,
          isInit: true,
          isInitiating: false,
        });
        return;
      }

      // For domain limit of 1, ensure we set the single site
      if (domainLimit === 1 && authorizedSites.length > 0) {
        // Use the first site as the selected site
        set({ siteUrl: authorizedSites[0] });
      } else if (authorizedSites.length > 0) {
        // For Infinity or if they still have sites available
        set({ siteUrl: authorizedSites[0] });
      }

      const { initGscData } = get();
      await initGscData();
      set({ isInit: true, isInitiating: false });
    },

    /**
     * Initialize GSC data after obtaining token
     */
    initGscData: async () => {
      // This method can be expanded with more GSC data initialization logic
      // Currently kept minimal with placeholders for future expansion
      return;
    },

    /**
     * Integrate with Google Search Console
     * Handles the OAuth flow callback and token storage
     */
    integrateGSC: async (
      code: string,
      navigate: ReturnType<typeof useNavigate>,
      addNotification: (notification: Omit<StoreNotification, "id">) => void
    ) => {
      try {
        // Start by setting initiating state
        set({ isInitiating: true, isInit: false });

        const currentUrl = new URL(window.location.href);
        const baseUrl = `${currentUrl.protocol}//${currentUrl.host}`;
        const redirectUri = `${baseUrl}/ignore-auth`;

        // Get domain limits first
        const domainLimitResponse = await axios.post(
          "/searchConsole/getGscDomainsAllowed"
        );
        const domains_allowed = domainLimitResponse.domains_allowed;
        const domainLimit = domains_allowed === -1 ? Infinity : domains_allowed;
        set({ domainLimit });

        // Exchange the authorization code for access and refresh tokens
        const tokenResponse = await axios.post("/searchConsole/getTokenUrl", {
          code,
          redirect_uri: redirectUri,
        });

        if (tokenResponse.auth_token?.access_token) {
          const authResponse = tokenResponse.auth_token;

          // Ensure the access token is valid
          const accessToken = storage.getToken();
          if (!accessToken) {
            throw new Error("Access token is missing or invalid");
          }

          // Save the refresh token and get authorized sites
          const response = await axios.post("/searchConsole/saveRefreshToken", {
            refresh_token: authResponse.refresh_token,
          });
          const authorizedSites = response.gsc_tracked_domains || [];
          //Set Access Token first so that we can use it to get the sites
          set({ accessToken: authResponse.access_token });

          // Get all GSC sites
          const { getSites } = get();
          const gscSites = await getSites();

          // Explicitly set siteUrl first if we have authorized sites
          if (authorizedSites.length > 0) {
            set({ siteUrl: authorizedSites[0] });
          }

          // Then set the rest of the state
          set({
            isGSCEnabled: true,
            refreshToken: authResponse.refresh_token,
            authorizedSites,
            gscSites: gscSites !== "error" ? gscSites : [],
            showSelectSiteDialog: false,
            showUpgradeToContinueDialog: false,
          });

          // Handle domain limit exceeded case
          if (authorizedSites.length > domainLimit && domainLimit > 0) {
            set({
              showUpgradeToContinueDialog: true,
              isInit: true,
              isInitiating: false,
            });
            navigate("/app/content-opportunities");
            return;
          }

          // Handle site selection logic
          if (domainLimit === 1 && authorizedSites.length > 0) {
            // For single domain limit, use the first authorized site
            set({ siteUrl: authorizedSites[0] });
          } else if (gscSites !== "error" && gscSites.length > 1) {
            // For multiple sites, check if selection dialog is needed
            const needsToSelectSite =
              authorizedSites.length === 0 ||
              !gscSites.includes(authorizedSites[0]);

            if (needsToSelectSite && domainLimit === Infinity) {
              set({ showSelectSiteDialog: true });
              //If user hasn't picked a site and domainlimit is 1 and there are more 
              //than 1 GSC sites show selection dialog
            } else if(needsToSelectSite && domainLimit == 1){
              set({ showSelectSiteDialog: true });
            } else if (authorizedSites.length > 0) {
              set({ siteUrl: authorizedSites[0] });
            }
          } else if (authorizedSites.length > 0) {
            // Default to first authorized site if no other conditions met
            set({ siteUrl: authorizedSites[0] });
          }

          // Initialize GSC data
          const { initGscData } = get();
          await initGscData();

          // Finally set initialization complete
          set({ isInit: true, isInitiating: false });

          navigate("/app/content-opportunities");
          return;
        }
      } catch (error) {
        set({ isInit: true, isInitiating: false }); // Ensure we clear loading state on error
        addNotification({
          type: "error",
          title: "Integration Error",
          message: "Failed to integrate with Google Search Console.",
        });
      }
    },

    /**
     * Set an authorized site as the active site for GSC data
     */
    setAuthorizedSite: async (site: string) => {
      const { refreshToken, setSiteUrl } = get();
      try {
        await axios.post("/setIntegration", {
          refresh_token: refreshToken,
          name: "SearchConsole",
          gsc_tracked_domains: [site],
        });
        set({ authorizedSites: [site] });
        setSiteUrl(site);
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to set authorized site.",
          variant: "destructive",
        });
      }
    },

    /**
     * Disable Search Console integration
     */
    disableSearchConsole: async () => {
      set({ isLoadingDisableSearchConsole: true });
      try {
        await axios.post("/disableIntegration", { name: "SearchConsole" });

        // Update the store to reflect the disabled integration
        set({
          isGSCEnabled: false,
          accessToken: null,
          siteUrl: null,
        });

        // Show a success message
        toast({
          title: "Integration Removed",
          description: "Search Console integration was successfully removed.",
        });
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to remove Search Console integration.",
          variant: "destructive",
        });
      } finally {
        set({ isLoadingDisableSearchConsole: false });
      }
    },
  })
);

/**
 * Fetch GSC token information from the server
 */
async function fetchGSCToken(): Promise<GSCTokenInfo> {
  // Check if GSC integration is enabled
  const integrationResponse: { SearchConsole?: boolean } = await axios.post(
    "/getEnabledIntegrations",
    {}
  );

  if (!integrationResponse.SearchConsole) {
    return {
      isGSCEnabled: false,
      accessToken: null,
      authorizedSites: [],
      refreshToken: null,
    };
  }

  // Get integration details if enabled
  const searchConsoleIntegrationResponse = await axios.post(
    "/fetchIntegrationInfo",
    { integration_type: "SearchConsole" }
  );

  try {
    const refreshToken =
      // @ts-ignore
      searchConsoleIntegrationResponse[0]?.metadataAsMap?.refresh_token;

    if (!refreshToken) {
      return {
        isGSCEnabled: false,
        accessToken: null,
        authorizedSites: [],
        refreshToken: null,
      };
    }

    const authorizedSites =
      // @ts-ignore
      searchConsoleIntegrationResponse[0]?.gsc_tracked_domains || [];

    // Exchange refresh token for a new access token
    const tokenResponse = await axios.post(
      "/searchConsole/getAccessTokenFromRefreshToken",
      { refresh_token: refreshToken }
    );

    return {
      isGSCEnabled: true,
      // @ts-ignore
      accessToken: tokenResponse.access_token,
      authorizedSites,
      refreshToken,
    };
  } catch (error) {
    return {
      isGSCEnabled: false,
      accessToken: null,
      authorizedSites: [],
      refreshToken: null,
    };
  }
}
