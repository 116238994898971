/// <reference types="vite-plugin-svgr/client" />
import { ArticleCard, Button } from "@/components/Elements";
import { GenerateContentBriefButton } from "@/features/auto-brief/components/GenerateContentBriefButton";
import {
  useHandlePasteCitation,
  useHandlePasteContent,
  useHandlePasteHeadings,
} from "@/features/documents/utils/pasting";
import SerpChart from "@/features/serp/components/SerpChart";
import { useDocumentStore } from "@/stores/document";
import { useSerpStore } from "@/stores/serp";
import React, { useMemo } from "react";
import { TbAdjustmentsHorizontal, TbChartBar } from "react-icons/tb";
import { usePlaceholderStore } from "../../DocumentEditor";
import { useHeaderNavigation } from "../../hooks/useHeaderNavigation";
import CustomizeResults from "./CustomizeResults";
import { PeopleAlsoAsk } from "./Questions";

export const CustomizeResultsButton = ({
  setCustomizeResultsVisible,
}: {
  setCustomizeResultsVisible: (value: boolean) => void;
}) => {
  return (
    <Button
      variant="buttonIcon"
      size="lg"
      tooltipContent="Customize search results"
      buttonIcon={<TbAdjustmentsHorizontal />}
      onClick={() => setCustomizeResultsVisible(true)}
    ></Button>
  );
};

export const Overview = React.memo(({ setIsLoadingSerp }) => {
  const [customizeResultsVisible, setCustomizeResultsVisible] =
    React.useState(false);
  const [generateBriefOpen, setGenerateBriefOpen] = React.useState(false);
  const [isSerpChartOpen, setSerpChartOpen] = React.useState(false);
  const { setShowPlaceholder, setShowLinePlaceholder } = usePlaceholderStore();
  const { isVisible } = useHeaderNavigation();
  const { document } = useDocumentStore();
  const docId = document.id;
  const { serp } = useSerpStore();
  const currentSerp = serp[docId] || {
    results: [],
    articles: [],
  };
  const blacklist = document.metadata?.blacklist || {};
  const handlePasteHeadings = useHandlePasteHeadings();
  const handlePasteCitation = useHandlePasteCitation();
  const handlePasteContent = useHandlePasteContent();

  const validArticles = useMemo(() => {
    return (currentSerp.articles || [])
      .filter((article) => article.isValid && !blacklist[article.url])
      .sort((a, b) => {
        if (a.isCustomImport && !b.isCustomImport) return -1;
        if (!a.isCustomImport && b.isCustomImport) return 1;
        return a.index - b.index;
      })
      .map((article, index, array) => {
        const rank =
          array
            .slice(0, index)
            .filter((prevArticle) => !prevArticle.isCustomImport).length + 1;

        let updatedArticle = { ...article };
        if (article.error_msg && article.error_msg.length > 0) {
          const matchingResult = currentSerp.results.find(
            (result) => result.url === article.url
          );
          if (matchingResult) {
            updatedArticle.title = matchingResult.title;
            updatedArticle.description = matchingResult.description;
            updatedArticle.isProcessed = false;
          }
        }

        return {
          ...updatedArticle,
          rank: article.isCustomImport ? null : rank,
        };
      });
  }, [currentSerp.articles, currentSerp.results, blacklist]);

  if (customizeResultsVisible) {
    return (
      <CustomizeResults
        setCustomizeResultsVisible={setCustomizeResultsVisible}
        setIsLoadingSerp={setIsLoadingSerp}
      />
    );
  }

  return (
    <div className="flex">
      <div className="w-full">
        <div className="pl-4 pr-1">
          <PeopleAlsoAsk
            questions={currentSerp.peopleAlsoAsk}
            renderEmptyContent={false}
          />
        </div>
        <div className="pl-4 pt-2 w-full">
          <div className="flex items-end justify-between border-b pb-2 border-zinc-100 dark:border-zinc-800 w-full">
            <div className="flex items-center">
              <span className="font-semibold text-zinc-800 dark:text-zinc-200">
                {validArticles.length}
              </span>
              <span className="text-xs text-zinc-500 ml-1 pt-0.5">
                search results
              </span>
            </div>
            <div className="flex items-center">
              <GenerateContentBriefButton
                open={generateBriefOpen}
                setOpen={setGenerateBriefOpen}
                setShowPlaceholders={setShowPlaceholder}
                setShowLinePlaceholder={setShowLinePlaceholder}
                trigger={
                  <Button
                    variant="outlineBlur"
                    tooltipContent="Generate content brief"
                    size="2xs"
                    className="mr-2"
                  >
                    Generate brief
                  </Button>
                }
              />
              <SerpChart
                searchQuery={document.query}
                docId={docId}
                open={isSerpChartOpen}
                setOpen={setSerpChartOpen}
                trigger={
                  <Button
                    variant="buttonIcon"
                    size="lg"
                    tooltipContent="Open SERP Insights"
                    buttonIcon={<TbChartBar />}
                    onClick={() => setSerpChartOpen(true)}
                  ></Button>
                }
              />
              <CustomizeResultsButton
                setCustomizeResultsVisible={setCustomizeResultsVisible}
              />
            </div>
          </div>
        </div>

        {validArticles.map((article, index) => {
          const links = article.links?.length ?? 0;

          return (
            <ArticleCard
              isCustomImport={article.isCustomImport}
              key={index}
              title={article.title}
              description={article.description}
              assets={article.assets}
              url={article.url}
              rank={article.rank || 0}
              wordCount={article.word_count}
              links={links}
              domainAuthority={article.domainAuthority}
              isProcessed={article.isProcessed}
              handlePasteHeadings={handlePasteHeadings}
              handlePasteCitation={handlePasteCitation}
              handlePasteContent={handlePasteContent}
            />
          );
        })}
      </div>
    </div>
  );
});
