import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/Elements";
import { axios } from "@/lib/axios";
import { cn } from "@/utils/style";
import { ExternalLink } from "lucide-react";
import React from "react";

export const generateCrossAppToken = (): Promise<{ redirectUrl: string }> => {
    return axios.get(`/api/auth/generate-cross-token`);
};

const AppToggle = ({ className }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const currentHost = window.location.hostname;
  const isAppFrase = currentHost.includes("dev-beta.frase.io");
  //const isAppFrase = true;
  const isAnswersFrase = currentHost.includes("answers-dev.frase.io");

  const targetApp = isAppFrase ? "Frase Answers" : "Frase Content";

  const handleToggle = async () => {
    try {
      setIsLoading(true);
      const response = await generateCrossAppToken();
      
      if (response.redirectUrl) {
        window.location.href = response.redirectUrl;
      }
    } catch (error) {
      console.error("Error toggling between apps:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Only show the toggle if we're on one of the known apps
  if (!(isAppFrase || isAnswersFrase)) {
    return null;
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outlineBlur"
            size="sm"
            onClick={handleToggle}
            isLoading={isLoading}
            startIcon={<ExternalLink className="h-3.5 w-3.5" />}
            className={cn(
              "h-8 mr-2 border-zinc-200 dark:border-zinc-700 text-zinc-700 dark:text-zinc-300",
              className
            )}
          >
            {targetApp}
          </Button>
        </TooltipTrigger>
        <TooltipContent side="bottom">
          <p>Switch to {targetApp} Frase</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default AppToggle;