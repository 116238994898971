import {
  Button,
  DialogCloseButton,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/Elements";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/Elements/Chart/Chart";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Elements/Dialog/Dialog";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { breakDownURL } from "@/features/documents/utils/serp";
import { getPlanNickname } from "@/features/subscription/utils/getPlanNickname";
import { UpgradePlanDialog } from "@/features/upgrade/components/UpgradePlanDialog";
import { useSerpStore } from "@/stores/serp";
import { cn } from "@/utils/style";
import { useEffect, useState } from "react";
import { BiExport } from "react-icons/bi";
import {
  TbChevronDown,
  TbFileSpreadsheet,
  TbFileTypeCsv,
  TbLock,
} from "react-icons/tb";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import * as XLSX from "xlsx";

function sortByPosition(a, b) {
  if (a.index > b.index) return 1;
  if (a.index < b.index) return -1;
  return 0;
}

const SerpChart = ({ docId, searchQuery, open, setOpen, trigger }) => {
  const [chartData, setChartData] = useState([]);
  const [metricName, setMetricName] = useState("Word Count");
  const { serp } = useSerpStore();
  const { data: subscriptionData } = useSubscription({});
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);

  useEffect(() => {
    const currentSerp = serp[docId];
    if (!currentSerp) return;

    const sortedSerp = [...currentSerp.articles].sort(sortByPosition);
    const data = sortedSerp.map((value, index) => {
      const domain = breakDownURL(value.url);
      let metricValue = 0;

      switch (metricName) {
        case "Word Count":
          metricValue = value.word_count || 0;
          break;
        case "Headings":
          metricValue = value.assets ? value.assets.length : 0;
          break;
        case "Images":
          metricValue = value.images ? value.images.length : 0;
          break;
        case "Backlinks":
          metricValue = value.links ? value.links.length : 0;
          break;
        case "Domain Rating":
          metricValue = value.domainAuthority || 0;
          break;
        case "Topic Score":
          metricValue = Math.max(0, value.topic_score.toFixed(0));
          break;
        default:
          break;
      }

      return {
        index: index + 1,
        metricValue,
        tooltip: `${metricValue} - ${domain}`,
      };
    });

    setChartData(data);
  }, [metricName, docId, serp]);

  const calculateStats = (data) => {
    const filteredData = data.filter((d) => d.metricValue > 0);
    if (filteredData.length === 0) return { avg: 0, min: 0, max: 0 };

    const values = filteredData.map((d) => d.metricValue);
    const avg = values.reduce((a, b) => a + b, 0) / values.length;
    const min = Math.min(...values);
    const max = Math.max(...values);

    return {
      avg: parseFloat(avg.toFixed(2)),
      min: parseFloat(min.toFixed(2)),
      max: parseFloat(max.toFixed(2)),
    };
  };

  const { avg, min, max } = calculateStats(chartData);

  const metrics = [
    "Word Count",
    "Topic Score",
    "Headings",
    "Images",
    "Backlinks",
    "Domain Rating",
  ];

  const chartConfig = {
    metricValue: {
      label: metricName,
      color: "#059669",
    },
  } satisfies ChartConfig;

  const hasAccess = (metric) => {
    const requiredPlan = ["Basic", "Team"];
    const planNickname = getPlanNickname(subscriptionData?.plan || "Free");
    return (
      (metric !== "Domain Rating" && metric !== "Backlinks") ||
      requiredPlan.includes(planNickname)
    );
  };

  const plans = [
    { name: "Basic Plan", path: "/app/settings/plans/basic" },
    { name: "Team Plan", path: "/app/settings/plans/team" },
  ];

  const handleUpgradeDialogClose = (close) => {
    close();
    setUpgradeDialogOpen(false);
  };

  const exportData = (format) => {
    const currentSerp = serp[docId];
    if (!currentSerp) return;

    const sortedSerp = [...currentSerp.articles].sort(sortByPosition);

    const sheetData = chartData.map((data) => {
      const source = data.tooltip.split(" - ")[1];
      const row = { Source: source };

      sortedSerp.forEach((value) => {
        const domain = breakDownURL(value.url);
        if (domain === source) {
          row["Ranking Position"] = value.index + 1;
          row["Word Count"] = value.word_count || 0;
          row["Headings"] = value.assets ? value.assets.length : 0;
          row["Images"] = value.images ? value.images.length : 0;
          row["Backlinks"] = value.backlinks || 0;
          row["Domain Rating"] = value.domainAuthority || 0;
          row["Topic Score"] = Math.max(0, value.topic_score.toFixed(0));
        }
      });

      return row;
    });

    const worksheet = XLSX.utils.json_to_sheet(sheetData);

    if (format === "csv") {
      const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
      const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `SERP_Metrics_${searchQuery}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (format === "excel") {
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "SERP Metrics");
      XLSX.writeFile(workbook, `SERP_Metrics_${searchQuery}.xlsx`);
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          {trigger || <Button variant="primary">Open SERP Insights</Button>}
        </DialogTrigger>
        <DialogContent
          className="h-[90vh] max-w-[1250px] mx-12 overflow-hidden"
          variant="charts"
        >
          <DialogHeader className="p-0 px-4 py-4 flex justify-between border-b dark:border-b-zinc-800">
            <DialogTitle>SERP Insights</DialogTitle>
            <DialogCloseButton close={() => setOpen(false)} />
          </DialogHeader>
          <DialogDescription className="px-4 pb-40 pt-4 overflow-hidden">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center text-zinc-500 dark:text-zinc-600 text-xs">
                <p className="border-r px-2 bg-zinc-100 border rounded-l-md py-0.5 dark:bg-zinc-800 dark:text-zinc-300 dark:border-zinc-700  whitespace-nowrap">
                  Search Query
                </p>
                <p className="font-medium px-2 border-r border-t border-b rounded-r-md py-0.5 text-zinc-800 dark:text-zinc-200 dark:border-zinc-700 whitespace-nowrap">
                  {searchQuery}
                </p>
              </div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outlineBlur"
                    size="xs"
                    startIcon={<BiExport />}
                    endIcon={<TbChevronDown />}
                  >
                    Export
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem onSelect={() => exportData("excel")}>
                    <TbFileSpreadsheet className="mr-2" />
                    Export view as Excel
                  </DropdownMenuItem>
                  <DropdownMenuItem onSelect={() => exportData("csv")}>
                    <TbFileTypeCsv className="mr-2" />
                    Export view as CSV
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <div className="flex flex-col items-center space-y-4 mb-4">
              <div className="font-medium text-zinc-900">SERP Metrics</div>
              <div className="flex space-x-2">
                {metrics.map((metric) => (
                  <Button
                    key={metric}
                    onClick={() => {
                      if (hasAccess(metric)) {
                        setMetricName(metric);
                      } else {
                        setUpgradeDialogOpen(true);
                      }
                    }}
                    startIcon={(!hasAccess(metric) && <TbLock />) || null}
                    variant={metricName === metric ? "primary" : "secondary"}
                    className={cn("px-4 py-2 flex items-center")}
                  >
                    {metric}
                  </Button>
                ))}
              </div>
              <div className="flex items-center space-x-4 text-zinc-500 dark:text-zinc-600 text-sm">
                <div className="flex items-center">
                  <span className="w-2 h-0.5 bg-red-500 inline-block mr-2"></span>
                  <strong className="text-zinc-800 dark:text-zinc-200 mr-1">
                    Avg:{" "}
                  </strong>{" "}
                  {avg.toFixed(2)}
                </div>
                <span className="text-zinc-400 dark:text-zinc-500">|</span>
                <div className="flex items-center">
                  <strong className="text-zinc-800 dark:text-zinc-200 mr-1">
                    Min:{" "}
                  </strong>{" "}
                  {min.toFixed(2)}
                </div>
                <span className="text-zinc-400 dark:text-zinc-500">|</span>
                <div className="flex items-center">
                  <strong className="text-zinc-800 dark:text-zinc-200 mr-1">
                    Max:{" "}
                  </strong>{" "}
                  {max.toFixed(2)}
                </div>
              </div>
            </div>

            <ResponsiveContainer height={"80%"} width={"100%"}>
              <ChartContainer config={chartConfig}>
                <BarChart
                  data={chartData}
                  className="text-zinc-800 dark:text-zinc-200"
                >
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="index"
                    tickLine={false}
                    tickFormatter={(value) => `#${value}`} // Ensure this is correctly formatting unique values
                    tickSize={2}
                    axisLine={false}
                    interval={0} // Force a tick for every data point
                    minTickGap={5}
                    label={{
                      value: "Ranking Position",
                      position: "insideBottom",
                      offset: -2,
                    }}
                  />
                  <YAxis
                    tickLine={false}
                    axisLine={false}
                    label={{
                      value: metricName,
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <ReferenceLine y={avg} stroke="red" strokeDasharray="3 3" />
                  <ChartTooltip
                    cursor={false}
                    content={
                      <ChartTooltipContent
                        color="#059669"
                        indicator="dot"
                        labelFormatter={(value, payload) => {
                          return payload[0].payload.tooltip;
                        }}
                      />
                    }
                  />
                  <Bar dataKey="metricValue" barSize={35} radius={4}>
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill="#059669" />
                    ))}
                  </Bar>
                </BarChart>
              </ChartContainer>
            </ResponsiveContainer>
          </DialogDescription>
        </DialogContent>
      </Dialog>

      {/* Upgrade Dialog */}
      <UpgradePlanDialog
        open={upgradeDialogOpen}
        onClose={handleUpgradeDialogClose}
        header="Unlock SERP metrics"
        description="Upgrade to the Basic or Team Plan to access domain rating and backlink metrics and enhance your SERP insights."
        plans={plans}
      />
    </>
  );
};

export default SerpChart;
