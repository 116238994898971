import produce from "immer";
import { LexicalEditor } from "lexical";
import { create } from "zustand";

type Editor = {
  textNodes: string[];
  headerNodes: string[];
  linkNodes: string[];
  imageNodes: string[];
  instance: LexicalEditor | null;
  title: string;
  wordCount: number;
  characterCount: number;
  activeTabIndex: number;
  splitTabIndex: number;
  isPopulated: boolean;
  optimizeViewVisible: boolean;
  optimizeScrollToTop: boolean;
  highlightTopics: boolean;
  isRefreshingDocument: boolean;
  isPasting: boolean;
  isSplitViewVisible: boolean;
};

type EditorStore = {
  editor: Editor;
  setEditor: (updater: (editorState: Editor) => void) => void;
  toggleSplitView: () => void;
};

export const useEditorStore = create<EditorStore>((set) => ({
  editor: {
    textNodes: [],
    headerNodes: [],
    linkNodes: [],
    imageNodes: [],
    instance: null,
    title: "",
    wordCount: 0,
    characterCount: 0,
    activeTabIndex: 0,
    splitTabIndex: 0,
    isPopulated: false,
    optimizeViewVisible: false,
    optimizeScrollToTop: false,
    highlightTopics: false,
    isRefreshingDocument: false,
    isPasting: false,
    isSplitViewVisible: false,
  },
  setEditor: (updater) =>
    set(produce((state: EditorStore) => updater(state.editor))),
  toggleSplitView: () =>
    set(
      produce((state: EditorStore) => {
        state.editor.isSplitViewVisible = !state.editor.isSplitViewVisible;
      })
    ),
}));
