import { useIsDocumentRoute } from "@/hooks/useIsDocumentRoute";
import { useNotificationStore } from "@/stores/notifications";
import { useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Logo,
} from "../Elements";
import FpsView from "./FpsView";

const EnviromentDisplay = () => {
  const env = import.meta.env.VITE_REACT_APP_ENVIRONMENT;

  return (
    <span className="text-xs text-zinc-600 dark:text-zinc-400 font-medium">
      {env[0].toUpperCase() + env.slice(1)}
    </span>
  );
};

const VersionDisplay = () => {
  const version = import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA;

  if (!version) {
    return null;
  }

  return (
    <span className="text-xs text-zinc-600 dark:text-zinc-400 font-medium">
      {version}
    </span>
  );
};

const ClearLocalStorage = () => {
  return (
    <Button
      size="2xs"
      variant="text"
      onClick={() => {
        window.localStorage.clear();
        window.location.reload();
      }}
    >
      Clear Local Storage
    </Button>
  );
};

const toggleMode = () => {
  document.documentElement.classList.toggle("dark");
  window.localStorage.isDarkMode =
    document.documentElement.classList.contains("dark");
};

export const DevToolbar = () => {
  const [visible, setVisible] = useState(true);
  const isDocumentRoute = useIsDocumentRoute();
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();

  if (!visible) return null;

  const documentQueries = queryClient.getQueriesData("document");
  const documentData =
    (documentQueries[documentQueries.length - 1] &&
      documentQueries[documentQueries.length - 1][1]) ||
    {};

  return (
    <div className="flex flex-shrink-0 h-8 w-full bg-zinc-50 dark:bg-zinc-900 border-z px-4 items-center z-50 border-t dark:border-t-zinc-800 space-x-4 justify-between">
      <div className="flex space-x-4">
        <Logo size="sm" renderText={false} imgClassName="h-4" />
        <EnviromentDisplay />
        <VersionDisplay />
        <FpsView />
      </div>
      <div className="flex space-x-2">
        <ClearLocalStorage />
        {isDocumentRoute && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="2xs" variant="text">
                Document Info
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-80 max-h-[calc(100vh-100px)] overflow-y-scroll">
              <DropdownMenuGroup>
                {Object.entries(documentData).map(([key, value]) => (
                  <DropdownMenuItem
                    className="whitespace-nowrap overflow-ellipsis"
                    key={key}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${key}: ${
                          typeof value === "object"
                            ? JSON.stringify(value)
                            : value
                        }`
                      );
                      addNotification({
                        type: "success",
                        title: "Copied to Clipboard",
                        message: `${key} copied successfully!`,
                      });
                    }}
                  >
                    <span className="w-24 bg-gray-200 dark:bg-gray-700 px-1.5 rounded truncate flex-shrink-0">
                      {key}:
                    </span>
                    <span className="ml-2">
                      {typeof value === "object"
                        ? JSON.stringify(value)
                        : value}
                    </span>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
        <Button size="2xs" variant="text" onClick={toggleMode}>
          Toggle Dark Mode
        </Button>
        <Button size="2xs" variant="text" onClick={() => setVisible(false)}>
          Hide Toolbar
        </Button>
      </div>
    </div>
  );
};
